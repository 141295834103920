import React from 'react';
import CreatableSelect from 'react-select/creatable';

import './Dropdown.scss';

export default function Dropdown(props) {
    const { label, options, onChange, name, error, placeholder, id } = props;
    return (
        <div className="Dropdown">
            <label htmlFor={id}>{label}</label>
            <CreatableSelect
                isClearable
                id={id}
                placeholder={placeholder}
                onInputChange={inputVal => {
                    if (inputVal) {
                        onChange({
                            target: {
                                name,
                                value: inputVal,
                            },
                        });
                    }
                }}
                onChange={selected => {
                    if (!selected) {
                        // user clicked the x (clear) button
                        onChange({
                            target: {
                                name,
                                value: '',
                            },
                        });
                    } else {
                        onChange({
                            target: {
                                name,
                                value: selected.value,
                            },
                        });
                    }
                }}
                name={name}
                className="Dropdown__select"
                classNamePrefix="Dropdown__select"
                options={options}
            />
            <span className="Dropdown__error">{error} &nbsp;</span>
        </div>
    );
}
