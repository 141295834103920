import React, { useState } from 'react';
import moment from 'moment';
import Button, { ButtonVariant } from '../../button/Button';
import ButtonBar, { ButtonBarAlign } from '../../button/ButtonBar';
import ConfirmModal from '../../modal/ConfirmModal';
import VisitorsApi from '../../api/VisitorApi';

import './Visitor.scss';

export default props => {
    const { user, loadUsers } = props;
    const [open, setOpen] = useState(false);
    const warningMessage = `${user.first_name} ${user.last_name}, you are about to sign out. Please confirm or cancel.`;

    function onButtonClick() {
        setOpen(true);
    }

    async function onConfirm() {
        setOpen(false);
        await VisitorsApi.signOutUser(user.id);
        loadUsers();
    }

    const signedIn = moment(user.login_date).format('dddd[,] MMMM Do [at] h:mm a');
    return (
        <div className="Visitor">
            <div className="Visitor__info">
                <h1 className="Visitor__info-name">
                    {user.first_name} {user.last_name}
                </h1>
                <p>Organization: {user.company_name}</p>
                <p>Citizenship: {user.citizenship}</p>
                <p>Escort/Visiting: {user.employee_escort}</p>
                <p>Signed in: {signedIn}</p>
            </div>
            <div className="Visitor__button">
                <ButtonBar align={ButtonBarAlign.right}>
                    <Button
                        label="Sign-Out"
                        variant={ButtonVariant.secondary}
                        onClick={onButtonClick}
                    >
                        SIGN OUT
                    </Button>
                    <ConfirmModal
                        cancelText="CANCEL"
                        confirmText="SIGN OUT"
                        content={warningMessage}
                        isOpen={open}
                        onCancel={() => setOpen(false)}
                        onConfirm={onConfirm}
                        title="WARNING"
                    />
                </ButtonBar>
            </div>
        </div>
    );
};
