import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Navbar from './navbar/NavBar';
import Routes from './routes';
import './App.scss';

function App() {
    return (
        <div className="App">
            <Router>
                <Navbar />
                <Routes />
            </Router>
        </div>
    );
}

export default App;
