import { fetch } from 'whatwg-fetch';
import Api from './ApiEndpoints';
import LocationManager from '../LocationManager';

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

const VisitorsApi = {
    async getAllVisitors() {
        const location = LocationManager.getLocation();
        const url = `${Api.GET_VISITORS}?location=${location}`;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'Error fetching the users, sorry about that.',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },

    async signInUser(visitor) {
        const url = `${Api.SIGN_USER_IN}`;
        try {
            const result = await postData(url, { visitor });
            return result;
        } catch (e) {
            return [e];
        }
    },

    async signOutUser(id) {
        const url = `${Api.SIGN_USER_OUT}`;
        try {
            const result = await postData(url, { id });
            return result;
        } catch (e) {
            return [e];
        }
    },
};
export default VisitorsApi;
