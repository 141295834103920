import Cookies from 'js-cookie';

const LOCATION_KEY = 'm2.location';

const HQ = '601 E 3rd St.';
const TT = '711 E Monument Ave.';
const OB = '444 E 2nd St.';

export default {
    headquarters: HQ,
    locations: [HQ, TT, OB],

    getLocation: () => {
        return Cookies.get(LOCATION_KEY) || HQ;
    },

    setLocation: loc => {
        Cookies.set(LOCATION_KEY, loc);
    },

    getEscorts: loc => {
        if (loc === TT) {
            // Tech Town Folks
            return [
                { value: 'AFMC AFRL/RGK', label: 'AFMC AFRL/RGK' },
                { value: 'Alex Hermes', label: 'Alex Hermes' },
                { value: 'Bill Harrison', label: 'Bill Harrison' },
                { value: 'Brian McJilton', label: 'Brian McJilton' },
                { value: 'Bruce Miller', label: 'Bruce Miller' },
                { value: 'Cayley Dymond', label: 'Cayley Dymond' },
                { value: 'Darryl Stimson', label: 'Darryl Stimson' },
                { value: 'Dave Best', label: 'Dave Best' },
                { value: 'David Shahady', label: 'David Shahady' },
                { value: 'Elizabeth Escamilla', label: 'Elizabeth Escamilla' },
                { value: 'Elliot Leigh', label: 'Elliot Leigh' },
                { value: 'Jack Owsley', label: 'Jack Owsley' },
                { value: 'James Bieryla', label: 'James Bieryla' },
                { value: 'Jeff Graley', label: 'Jeff Graley' },
                { value: 'Jeremy Evans', label: 'Jeremy Evans' },
                { value: 'Jim Sweeney', label: 'Jim Sweeney' },
                { value: 'Kelley Kiernan', label: 'Kelley Kiernan' },
                { value: 'Kim Yoder', label: 'Kim Yoder' },
                { value: 'Matt Howard', label: 'Matt Howard' },
                { value: 'Mile 2 Group', label: 'Mile 2 Group' },
                { value: 'Robert Slater', label: 'Robert Slater' },
                { value: 'Ron Clyburn', label: 'Ron Clyburn' },
                { value: 'Sarah French', label: 'Sarah French' },
                { value: 'SB Group', label: 'SB Group' },
                { value: 'SB Legal Council', label: 'SB Legal Council' },
                { value: 'Steve Dranchak', label: 'Steve Dranchak' },
                { value: 'Sunita Chavan', label: 'Sunita Chavan' },
                { value: 'Tim Palen', label: 'Tim Palen' },
                { value: 'Tricia Randall', label: 'Tricia Randall' },
            ];
        }
        // HQ Folks
        return [
            { value: 'Alex Morison', label: 'Alex Morison' },
            { value: 'Amanda Walther', label: 'Amanda Walther' },
            { value: 'Amy Linkous', label: 'Amy Linkous' },
            { value: 'Dave Best', label: 'Dave Best' },
            { value: 'Jeff Graley', label: 'Jeff Graley' },
            { value: 'Jenn Mullins', label: 'Jenn Mullins' },
            { value: 'Randy Hinders', label: 'Randy Hinders' },
            { value: 'Raphael Carranza', label: 'Raphael Carranza' },
            { value: 'Scott Galster', label: 'Scott Galster' },
            { value: 'Tom Mitchell', label: 'Tom Mitchell' },
        ];
    },
};
