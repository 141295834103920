import React from 'react';
import { Route } from 'react-router-dom';

import HomePage from './home/HomePage';
import SettingsPage from './settings/SettingsPage';
import SignInPage from './signin/SignInPage';

const routes = () => {
    return (
        <>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/settings" component={SettingsPage} />
        </>
    );
};

export default routes;
