import React from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Button, { ButtonVariant } from '../button/Button';
import ButtonBar, { ButtonBarAlign } from '../button/ButtonBar';
import LocationManager from '../LocationManager';

import './SettingsPage.scss';

export default () => {
    const history = useHistory();
    const location = LocationManager.getLocation();

    const current = {
        label: location,
        value: location,
    };

    const options = [];
    LocationManager.locations.forEach(loc => {
        options.push({
            label: loc,
            value: loc,
        });
    });

    return (
        <div className="SettingsPage">
            <div className="SettingsPage__title">
                <h1>Visitor Log Application Settings</h1>
            </div>
            <div className="SettingsPage__settings">
                <div className="SettingsPage__setting">
                    <label htmlFor="tablet_location">Tablet Location</label>
                    <Select
                        id="tablet_location"
                        options={options}
                        defaultValue={current}
                        onChange={selected => {
                            LocationManager.setLocation(selected.value);
                        }}
                    />
                </div>
            </div>
            <ButtonBar align={ButtonBarAlign.right}>
                <Button
                    variant={ButtonVariant.secondary}
                    onClick={() => {
                        history.push('/');
                    }}
                >
                    Back to Home Screen
                </Button>
            </ButtonBar>
        </div>
    );
};
