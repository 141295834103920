import React from 'react';
import Textbox from '../textbox/Textbox';

export default function VisitorNames(props) {
    const { firstError, lastError, onChange, firstValue, lastValue, index } = props;
    return (
        <div className="SignInForm__fieldset">
            <Textbox
                name="first_name"
                id="First Name*"
                error={firstError}
                onChange={e => onChange(e, index)}
                value={firstValue}
                placeholder="First"
                label="First Name*"
            />
            <Textbox
                name="last_name"
                id="Last Name*"
                error={lastError}
                onChange={e => onChange(e, index)}
                value={lastValue}
                placeholder="Last"
                label="Last Name*"
            />
        </div>
    );
}
