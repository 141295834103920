import React from 'react';
import Visitor from './Visitor';
import './VisitorList.scss';

export default props => {
    const { users, loadUsers } = props;
    return (
        <div className="VisitorList">
            {users &&
                users.map(user => {
                    return <Visitor key={user.id} user={user} loadUsers={loadUsers} />;
                })}
        </div>
    );
};
