import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonVariant } from '../button/Button';
import ButtonBar, { ButtonBarAlign } from '../button/ButtonBar';
import LocationManager from '../LocationManager';
import VisitorList from './visitor/VisitorList';
import VisitorsApi from '../api/VisitorApi';
import Spinner from '../components/spinner/Spinner';

import { ReactComponent as SettingsIcon } from './cog.svg';

import './HomePage.scss';

export default () => {
    const history = useHistory();

    function onClick() {
        history.push('/signin');
    }

    const [users, setUsers] = useState(null);

    // eslint-disable-next-line consistent-return
    async function loadUsers() {
        try {
            const results = await VisitorsApi.getAllVisitors();
            // inspecting results to make sure theyre there
            setUsers(results);
        } catch (error) {
            return 'We are having issues reaching the API, sorry about that.';
        }
    }

    // Do this only one time. (componentDidMount)
    useEffect(() => {
        loadUsers();

        // Calling "setInterval" once in the componentDidMount, refreshing the page essentially every minute if the users have changed
        setInterval(loadUsers, 60000);
    }, []);

    let content = <Spinner />;

    // if users is truthy its length > 0, display the string, if not, display nothing
    if (users && users.length > 0) {
        content = (
            <>
                <p>CURRENTLY SIGNED IN ({users.length})</p>
                <VisitorList users={users} loadUsers={() => loadUsers()} />
            </>
        );
    } else if (users && users.error) {
        content = <p>{users.message}</p>;
    } else if (users) {
        content = <p>No visitors currently in the office.</p>;
    }

    const location = LocationManager.getLocation();

    return (
        <div className="HomePage">
            <div className="HomePage__title">
                <h1>Welcome to Mile Two</h1>
                <h2>{location}</h2>
            </div>
            <ButtonBar align={ButtonBarAlign.center}>
                <Button label="Sign-In" variant={ButtonVariant.primary} onClick={onClick}>
                    SIGN IN
                </Button>
            </ButtonBar>
            {content}

            <div className="HomePage__settings">
                <Button
                    onClick={() => {
                        history.push('/settings');
                    }}
                >
                    <SettingsIcon />
                </Button>
            </div>
        </div>
    );
};
