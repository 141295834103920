import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Textbox from '../components/textbox/Textbox';
import Dropdown from '../components/dropdownmenu/Dropdown';
import Button, { ButtonVariant } from '../button/Button';
import ButtonBar, { ButtonBarAlign } from '../button/ButtonBar';
import InfoModal from '../modal/InfoModal';
import LocationManager from '../LocationManager';
import VisitorsApi from '../api/VisitorApi';
import VisitorNames from '../components/visitornames/VisitorNames';
import Spinner from '../components/spinner/Spinner';

const reasonVisiting = [
    { value: 'Meeting', label: 'Meeting' },
    { value: 'Interview', label: 'Interview' },
    { value: 'Visiting Employee', label: 'Visiting Employee' },
    { value: 'Building Maintenance', label: 'Building Maintenance' },
];

const signInMessage = 'Your sign in is complete! Come back and sign out before you leave.';
const errorMessage = 'Field cannot be empty.';

export default () => {
    const location = LocationManager.getLocation();

    const [values, setValues] = useState({
        visitorList: [{ first_name: '', last_name: '' }],
        company_name: '',
        citizenship: 'USA',
        reason_visiting: '',
        employee_escort: '',
        location,
        login_date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
        logout_date: null,
    });

    const [isLoading, setIsLoading] = useState(false);

    const [errors, setErrors] = useState({ errorList: [{ first_name: '', last_name: '' }] });

    const [open, setOpen] = useState(false);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    const handleNameChange = (e, i) => {
        const { name, value } = e.target;

        // Make a copy of the object to be able to change visitorList.
        const copy = { ...values };

        // Find the indexed item to edit based on the index passed in.
        const selectedItem = copy.visitorList[i];

        // Edit the item name (first name or last name) and set it to the value (typed value) passed in.
        selectedItem[name] = value;

        // Replace the item at this index with the updated item.
        copy.visitorList[i] = selectedItem;

        // Save state.
        setValues({
            ...values,
            visitorList: copy.visitorList,
        });
    };

    // checks value of field, if empty, returns message for error span
    const checkEmpty = fieldValue => {
        if (!fieldValue) {
            return errorMessage;
        }
        return null;
    };

    // this function is to add a new fn ln pair for values and errors
    const incrementVisitor = e => {
        e.preventDefault();

        setValues({
            ...values,
            visitorList: [...values.visitorList, { first_name: '', last_name: '' }],
        });

        setErrors({
            ...errors,
            errorList: [...errors.errorList, { first_name: '', last_name: '' }],
        });
    };

    async function addVisitor() {
        setIsLoading(true);
        await VisitorsApi.signInUser(values);
        setIsLoading(false);

        setOpen(true);
    }

    const validateForm = e => {
        e.preventDefault();

        // Converts the login_date format back to an ISO string for database.
        values.login_date = moment(values.login_date).toISOString();

        if (
            values.visitorList[0].first_name &&
            values.visitorList[0].last_name &&
            values.company_name &&
            values.citizenship &&
            values.reason_visiting &&
            values.employee_escort &&
            values.login_date
        ) {
            addVisitor(values);
        } else {
            const newErrors = {};
            newErrors.errorList = [];
            for (let i = 0; i < values.visitorList.length; i += 1) {
                const visitorError = {
                    first_name: checkEmpty(values.visitorList[i].first_name),
                    last_name: checkEmpty(values.visitorList[i].last_name),
                };
                newErrors.errorList[i] = visitorError;
            }
            newErrors.company_name = checkEmpty(values.company_name);
            newErrors.citizenship = checkEmpty(values.citizenship);
            newErrors.reason_visiting = checkEmpty(values.reason_visiting);
            newErrors.employee_escort = checkEmpty(values.employee_escort);

            setErrors(newErrors);
            values.login_date = moment().format('YYYY-MM-DD[T]HH:mm:ss');
        }
    };

    let content = (
        <div>
            <Spinner />
        </div>
    );

    if (!isLoading) {
        content = (
            <>
                <ButtonBar className="SignInForm__fieldset" align={ButtonBarAlign.center}>
                    <div>
                        <Link className="SignInForm__fieldset__link" to="/">
                            <Button
                                className="SignInForm__fieldset__cancelButton"
                                label="Cancel-Button"
                                type="button"
                            >
                                CANCEL
                            </Button>
                        </Link>
                    </div>
                    <div>
                        <Button
                            className="SignInForm__fieldset__signInButton"
                            label="Sign-In-Button"
                            variant={ButtonVariant.primary}
                            onClick={validateForm}
                            data-testid="sign-in"
                            type="button"
                        >
                            SIGN IN
                        </Button>
                        <Link to="/">
                            <InfoModal
                                isOpen={open}
                                onClose={() => setOpen(false)}
                                title="SIGN IN COMPLETE"
                                content={signInMessage}
                                buttonText="GOT IT!"
                            />
                        </Link>
                    </div>
                </ButtonBar>
            </>
        );
    }

    let maxDate = new Date();
    maxDate = moment(maxDate).format('yyyy-MM-ddThh:mm');

    return (
        <form className="SignInForm">
            {values &&
                values.visitorList &&
                values.visitorList.map(
                    (visitor, i) => (
                        <VisitorNames
                            key={i}
                            index={i}
                            firstError={errors.errorList[i].first_name}
                            lastError={errors.errorList[i].last_name}
                            onChange={handleNameChange}
                            firstValue={visitor.first_name}
                            lastValue={visitor.last_name}
                        />
                    ),
                    errors
                )}
            <div className="SignInForm__buttonFieldset">
                <Button
                    className="SignInForm__buttonFieldset__multipleUserButton"
                    label="Multiple-User-Button"
                    onClick={incrementVisitor}
                    variant={ButtonVariant.secondary}
                    type="button"
                >
                    + ADD VISITOR
                </Button>
            </div>
            <div className="SignInForm__fieldset">
                <Textbox
                    id="Company*"
                    name="company_name"
                    error={errors.company_name}
                    onChange={handleInputChange}
                    value={values.company_name}
                    placeholder="Company Name or N/A"
                    label="Company*"
                />
                <Textbox
                    id="Citizenship*"
                    name="citizenship"
                    error={errors.citizenship}
                    onChange={handleInputChange}
                    value={values.citizenship}
                    label="Citizenship*"
                />
            </div>
            <div className="SignInForm__fieldset">
                <Dropdown
                    name="reason_visiting"
                    id="Reason for Visiting*"
                    error={errors.reason_visiting}
                    placeholder="Select or type a reason..."
                    onChange={handleInputChange}
                    value={values.reason_visiting}
                    options={reasonVisiting}
                    label="Reason for Visiting*"
                />
                <Dropdown
                    name="employee_escort"
                    id="Escort / Visiting who*"
                    placeholder="Select or type a name..."
                    error={errors.employee_escort}
                    onChange={handleInputChange}
                    value={values.employee_escort}
                    options={LocationManager.getEscorts(location)}
                    label="Escort / Visiting who*"
                />
            </div>

            <div className="SignInForm__datePicker">
                <div className="Textbox">
                    <label htmlFor="date">Time Signed In (autofilled to now)</label>
                    <input
                        type="datetime-local"
                        id="date"
                        name="login_date"
                        max={maxDate}
                        onChange={handleInputChange}
                        value={values.login_date}
                    />
                </div>
            </div>
            {content}
        </form>
    );
};
