import React from 'react';
import LocationManager from '../LocationManager';
import SignInForm from './SignInForm';

import './SignInPage.scss';

export default () => {
    const location = LocationManager.getLocation();

    return (
        <div className="SignInPage">
            <div className="SignInPage__title">
                <h1>Sign In</h1>
                <h2>{location}</h2>
            </div>

            <SignInForm />
        </div>
    );
};
