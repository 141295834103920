import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import Logo from '../logo/primary-greenbg@2x.png';

const NavBar = () => {
    return (
        <div className="NavBar">
            <div className="NavBar__logo">
                <Link to="/">
                    <img src={Logo} alt="icon" />
                </Link>
            </div>
        </div>
    );
};

export default NavBar;
